import { Fragment, useState } from "react";
import { useAuth } from "@/hooks/useAuth";
import Autocomplete from "./Autocomplete";
import { useNavigate } from "react-router-dom";
import NotificationCenter from "./Notifications/NotificationCenter";
import useElastic from "../hooks/useElastic";
import { formatCurrency } from "../utils/format";
import keywordBlacklist from "../utils/keywordBlacklist";
import { bookStatus, orderStatus, personTypes } from "../utils/types";
import { hyphenate } from 'isbn3';
import ProfileDialog from "./ProfileDialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "./ui/dropdown-menu";
import { Button } from "./ui/button";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { ArrowLeftRight, Frown, LogOut, Search, User } from "lucide-react";
import { Badge } from "./ui/badge";

export default (props) => {
    const { user, logout, publishers, unimpersonate } = useAuth();
    const navigate = useNavigate();
    const { search } = useElastic();
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);
    const handleLogout = () => {
        logout();
    }
 
	let usernameInitials = ((name: string) =>{
		let initials = name[0]
		for(let i = 1; i < name.length; i++){
			if(name[i-1] == " " && name[i] !== " ") initials += name[i];
		}
		return initials;
	})(user.name);

    return (
        <header className="relative top-0 w-full shadow z-30 bg-white">
            <div className="flex items-center px-4 lg:px-8 h-16 lg:h-20 gap-4">
                <button className="text-slate-500 focus:outline-none xl:hidden" onClick={props.onOpenSidebar}>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <div className="flex-1 ml-3 xl:ml-0 max-w-[500px] relative z-50">
                    <Autocomplete
                        placeholder="Suchbegriffe eingeben..."
						icon={<Search/>}
                        debounceTime={500}
                        inputClassName={'lg:text-lg shadow-inner rounded-full h-12'}
                        containerClassName="lg:w-[600px]"
                        popoverAlign="start"
                        noResultsMessage={<div className="text-base justify-center flex items-center">
                            <Frown className="size-4 mr-2" />
                            Keine Suchergebnisse gefunden
                        </div>}
                        onSelect={(value) => {
                            if (value._index == 'books') {
                                navigate(`/books/view/${value._id}`, { replace: true });
                            } else if (value._index == 'persons') {
                                navigate(`/persons/view/${value._id}`);
                            } else if (value._index == 'orders') {
                                navigate(`/orders/view/${value._id}`);
                            } else if (value._index == 'invoices') {
                                if (value._source.order_ids.length > 0) {
                                    const order_id = value._source.order_ids[0];
                                    navigate(`/orders/view/${order_id}`);
                                }
                            } else if (value._index == 'bundles') {
                                navigate(`/books/bundles?id=${value._id}`);
                            }
                        }}
                        onSearch={async (query) => {

                            const elasticQuery = {
                                bool: {
                                    should: [
                                        ...query.trim().split(' ')
                                            .filter(word => !keywordBlacklist.includes(word))
                                            .map(word => {
                                                const rules = [
                                                    { term: { invoice_id: { value: word, boost: 15 } } },
                                                    { term: { _id: { value: word, boost: 20 } } },
                                                    { term: { client_name: { value: word, boost: 1 } } },
                                                    { term: { verkehrsnummer: { value: word, boost: 10 } } },
                                                    { fuzzy: { authors: { value: word, boost: 2 } } },
                                                    { wildcard: { code: { value: `*${word}*`, boost: 20 } } }
                                                ];

                                                if (word.replace(/[^0-9]/g, '') !== '') {
                                                    rules.push({ wildcard: { isbn13: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 80 } } });
                                                }

                                                return rules;
                                            }).flat(),
                                        { match: { code: { query, boost: 10 } } },
                                        { match: { article_number: { query, boost: 100 } } }
                                    ],
                                    must: [
                                        { terms: { publisher_id: publishers.map(publisher => publisher.id) } }
                                    ]
                                }
                            };

                            if (query.match(/[a-zA-Z]/g) !== null) {
                                elasticQuery.bool.should.push({ match: { full_name: { query, boost: 8, fuzziness: "AUTO" } }});
                                elasticQuery.bool.should.push({ match: { title: { query, boost: 8 } }});
                            }

                            const results = await search({
                                index: ['persons', 'orders', 'books', 'invoices', 'bundles'],
                                body: {
                                    min_score: 10,
                                    query: elasticQuery,
                                    size: 10
                                },
                            });
                            return results.hits?.hits;
                        }}
                        // TODO: Implement logic to only show results in their respective groups
						// groups={{
						// 	"Bücher"       : (item) => item._index === "books",
						// 	"Personen"     : (item) => item._index === "persons",
						// 	"Bestellungen" : (item) => item._index === 'orders',
						// 	"Rechnungen"   : (item) => item._index === 'invoices',
						// }}
                        renderItem={(item) => {
                            return (<div className={`w-full py-4 lg:p-4 gap-4 flex items-center leading-tight`}>
                                { item._index == 'persons' && <>
                                    <div className="flex-1">
                                        <strong>{ item._source.company ? item._source.company : item._source.firstname + ' ' + item._source.lastname }</strong><br />
                                        { item._source.company && (item._source.firstname || item._source.lastname) && <div className="text-sm font-medium">
                                            { item._source.firstname } { item._source.lastname }
                                        </div> }
                                        <span className="text-sm">OPNR #{ item._id }</span>
                                        { item._source.verkehrsnummer && <span className="text-sm"> &bull; VN { item._source.verkehrsnummer }</span> }
                                    </div>
                                    <div className="text-right">
                                        <Badge className="bg-amber-200 text-foreground pointer-events-none">{ item._source.types && item._source.types[0] ? personTypes[item._source.types[0]] : 'Person' }</Badge>
                                    </div>
                                </> }
                                { item._index == 'books' && <>
                                    <div className="flex-1">
                                        <strong>{ item._source.title }</strong><br />
                                        <div className="text-sm leading-tight">
                                            { hyphenate(item._source.isbn13) } &bull; { bookStatus[item._source.status] }
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <Badge className="bg-blue-200 text-foreground pointer-events-none">Buch</Badge>
                                        <div className="mt-1.5 text-xs text-muted-foreground">{ user.publishers.find(p => p.id == item._source.publisher_id)?.name }</div>
                                    </div>
                                </> }
                                { item._index == 'orders' && <>
                                    <div className="flex-1">
                                        <strong>{ item._source.code }</strong><br />
                                        <div className="text-sm leading-tight">
                                            { item._source.client_name } &bull; { orderStatus[item._source.status] }
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <Badge className="bg-green-200 text-foreground pointer-events-none">Bestellung</Badge>
                                        <div className="mt-1.5 text-xs text-muted-foreground">{ user.publishers.find(p => p.id == item._source.publisher_id)?.name }</div>
                                    </div>
                                </> }
                                { item._index == 'invoices' && <>
                                    <div className="flex-1">
                                        <strong>{ item._source.total >= 0 ? 'Rechnung' : 'Gutschrift' } { item._source.invoice_id }</strong><br />
                                        <div className="text-sm leading-tight">
                                            { item._source.client_name } &bull; { formatCurrency(item._source.total) } &bull; { item._source.status == 'paid' ? 'Bezahlt' : 'Offen'}
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <Badge className="bg-violet-200 text-foreground pointer-events-none">Rechnung</Badge>
                                        <div className="mt-1.5 text-xs text-muted-foreground">{ user.publishers.find(p => p.id == item._source.publisher_id)?.name }</div>
                                    </div>
                                </> }
                                { item._index == 'bundles' && <>
                                    <div className="flex-1">
                                        <strong>{ item._source.title }</strong><br />
                                        <div className="text-sm leading-tight">
                                            { item._source.article_number } &bull; { item._source.book_ids.length } Produkte
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <Badge className="bg-rose-200 text-foreground pointer-events-none">Bundle</Badge>
                                        <div className="mt-1.5 text-xs text-muted-foreground">{ user.publishers.find(p => p.id == item._source.publisher_id)?.name }</div>
                                    </div>
                                </> }
                            </div>)
                        }}
                    />
                    
                </div>
                <NotificationCenter />
                <ProfileDialog isOpen={profileDialogOpen} onClose={() => setProfileDialogOpen(false)} />
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <Button asChild variant="ghost" className="relative p-0 size:10 lg:size-12 rounded-full">
                            <Avatar className="size-10 lg:size-12">
                                <AvatarFallback>{ usernameInitials }</AvatarFallback>
                            </Avatar>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56" forceMount>
                        <DropdownMenuLabel className="font-normal">
                            <div className="flex flex-col space-y-1">
                                <p className="text-sm font-medium leading-none">
                                    { user.name }
                                </p>
                                <p className="text-xs leading-none text-muted-foreground">
                                    { user.email }
                                </p>
                            </div>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        { user.impersonator && <Fragment>
                            <DropdownMenuGroup>
                                <DropdownMenuItem onClick={() => unimpersonate()}>
                                    <ArrowLeftRight size={20} />
                                    Zu { user.impersonator.name } anmelden
                                </DropdownMenuItem>
                            </DropdownMenuGroup>
                            <DropdownMenuSeparator />
                        </Fragment> }
                        <DropdownMenuGroup>
                            <DropdownMenuItem onClick={() => setProfileDialogOpen(true)}>
                                <User size={20} />
                                <span>Mein Konto</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => handleLogout()}>
                                <LogOut size={20} />
                                <span>Abmelden</span>
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </header>
    )
}